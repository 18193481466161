.App {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #cecece;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    min-height: 100vh;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

/* .login-img{
  opacity: 0.2;
  width: 100vw;
  height: 100vh; 
} */
.App-logo {
    pointer-events: none;
    margin-right: 300px;
    z-index: 0;
    opacity: 0.5;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@media only screen and (max-width: 600px) {
    #dash-footer {
        top: 10px !important;
    }

    #main-img {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        max-width: 20vw;
        /* left: 0 !important; */
        /* display: none; */
    }

    #scroll-bar {
        position: absolute !important;
        top: 250px !important;
        height: calc(100vh - 250px) !important;
        width: 96% !important;
    }

    #filter-btn {
        display: none;
    }

    .menu-btn {
        max-width: calc(100vw / 10) !important;
    }

    #search-bar {
        flex-direction: column !important;
        align-items: center !important;
    }

    #add-order {
        flex-direction: column !important;
    }

    .menu-bar {
        max-height: 60vh !important;
        overflow: scroll !important;
        z-index: 9999 !important;
    }
}

@keyframes App-logo-spin {
    0% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(15deg);
    }
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 7em;
    height: 7em;
}

.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #181818;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.main-view {
    min-width: 300px;
    z-index: 100;
    position: absolute;
    right: 0;
    top: 0;
    width: 20vw;
    height: 100vh;
    background: #181818;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 120px;
    -webkit-box-shadow: 0 10px 6px -6px #000;
    -moz-box-shadow: 0 10px 6px -6px #000;
    box-shadow: 0 10px 6px -6px #000;
    line-height: normal;
}

.title-text-app {
    padding: 0;
    margin: 5px;
    font-size: 26px;
    font-weight: bold;
    color: #e8f0fe;
    z-index: 2;
}

.export-excel-btn {
    margin: 4px;
}

.MuiSvgIcon-root {
    color: #9a1d1d;
}