.center-app{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;  
    justify-content: center;
    background-color: #181818;
    padding: 10px;
}  

.menu-bar-autocomplete input{
    color: #e8f0fe;
}

tr td:hover{  
    background-color: #fcd767;  
}  

.MuiTableCell-root{
    padding: 0px !important; 
    padding-left: 4px !important; 
    padding-right: 4px !important; 
}
#collapseTable{
    background-color: red;
    width: 100px !important;
}