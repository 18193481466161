.modal-loading{
    position: absolute;
    width: 300px;
    height: 250px;
    border-radius: 5px;
    background: #181818;
    z-index: 999999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 25px;
  }
  .form label input{
    background: rgba(0, 0, 0, 0);
    color: #7c7c7c;
    border: 0;  
    outline: none;
    cursor: pointer;
  }
  .form label {
    font-weight: bold;
    /*color: #e9e9e9;*/
    width: 100%; 
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
  } 
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    color: #e9e9e9;
  }
  @media only screen and (max-width: 600px) { 
      .form-row{
        /* flex-direction: column; */
        /* overflow: auto;  */
        max-height: 70vh;
      }

  }

  #new-order-modal{
    z-index: 9999 !important;
  }

  .modal-alert {
      position: fixed;
      bottom: 0;
      right: 30px;
      padding: 10px;
      margin: 0;
      background: rgba(0, 0, 0, 0.8);
      text-align: center;
      z-index: 999;
      color: white;
      font-size: 16px;
      left: 30px;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.7);
      border-radius: 10px;
      opacity: 0;
      visibility: hidden;
      transition: bottom 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }

  .modal-alert.shown {
      visibility: visible;
      bottom: 30px;
      opacity: 1;
  }