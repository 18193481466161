.tab-app{
    width:100%;
    height:100%;
    padding:0px;
}
.tab-title-list{
    display:flex;
    flex-direction:row;
    margin:0px;
}
.tab-title{
    cursor:pointer;
    font-size:28px;
    border-top-left-radius:10px;
    border-top-right-radius:10px;
    list-style-type:none;
    padding:8px;
}
.tab-view{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.tab-view-inside{ 
    width:100%;
    height:100vh;
    padding: 10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center; 
}

.pagination-item {
    padding: 7px 17px !important;
    font-size: 21px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.pagination-item.active {
    font-weight: bold;
}

.pagination {
    display: flex;
    flex-wrap: wrap;
    margin: 25px auto !important;
}