.input-app{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border-radius: 5px;
    -webkit-box-shadow: 0 10px 6px -6px #000;
    -moz-box-shadow: 0 10px 6px -6px #000;
    box-shadow: 0 10px 6px -6px #000; */
    margin: 5px;
    z-index: 2;
}
.btns-input-order{
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 6px -6px #000;
    -moz-box-shadow: 0 10px 6px -6px #000;
    box-shadow: 0 10px 6px -6px #000;
    background: #101010;
    width: '100%';
}
.input-box{
    padding-left: 4px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    outline: none;
    cursor: pointer;
    font-size: 12px;
}
.input-app-inside{
    /* background: #101010; */
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 100%;
}
.icons-app{
    vertical-align: 'middle';
    color: #e8f0fe;
    margin-right: 5px;
    font-size: 20px;
}
.input-app-inside input{
    /* color: #e8f0fe; */
    cursor: pointer;
} 